(function () {
'use strict';

document.body.addEventListener("touchstart", function() {
    return false
});

const offsetHeader = document.querySelector('.site-header').offsetHeight;
const pageLinks = document.querySelectorAll('a[href^="' + baseurl + '/#"].scroll');
Array.prototype.forEach.call(pageLinks, link=>link.addEventListener("click", function() {
    const section = document.querySelector(this.hash);
    if (!section)
        return false;
    scrollToY(section.offsetTop - offsetHeader, 1000);
}));

}());
